import { useUserApi, useUserFieldsListApi } from '@entities/users'

const useUserInfoFields = (userId: number) => {
  const { data: user, isLoading: isUserLoading } = useUserApi(userId)
  const { data: userFieldsData } = useUserFieldsListApi()

  const userFieldsList = userFieldsData?.results
  const userFieldValues = user?.field_values

  const userFields = userFieldsList?.map(({ id, name, type }) => {
    const fieldValue = userFieldValues?.find(({ user_field }) => {
      return user_field === name
    })

    return {
      name: fieldValue?.value ? fieldValue?.id.toString() : id.toString(),
      label: name,
      value: fieldValue?.value || '',
      // В дальнейшем, в зависимости от типа, в rowsConfig будут возвращаться разные инпуты
      type,
    }
  })

  return { userFields, isLoading: isUserLoading }
}

export { useUserInfoFields }
