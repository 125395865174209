import { useUserApi } from '@entities/users'
import { useUpdateUser } from '@features/user'
import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { UserHeader, UserInfo } from '@widgets/settings'
import { useParams } from 'react-router'

const UserPage: ReactFc = () => {
  const { id } = useParams()
  const userId = parseInt(id!)
  const { data: user } = useUserApi(userId)

  const { isSaveButtonActive, handleUpdateUserFieldValues, isUserFieldValuesSubmitting, methods } =
    useUpdateUser()

  useMeta({ title: `${PAGE_TITLES.SETTINGS.USER.title} ${user?.username}` })

  return (
    <Layout
      header={
        <UserHeader
          onUpdateUserFieldValues={handleUpdateUserFieldValues}
          isUserFieldValuesSubmitting={isUserFieldValuesSubmitting}
          isSaveButtonActive={isSaveButtonActive}
        />
      }
    >
      <UserInfo methods={methods} isUserSubmitting={isUserFieldValuesSubmitting} />
    </Layout>
  )
}

export { UserPage }
