import { API_QUERY_KEY, queryClient } from '@shared/api'
import { type ApiAddUserFieldValue } from '@shared/api/types'

import {
  useAddUserFieldValueApi,
  useDeleteUserFieldValueApi,
  useUpdateUserFieldValueApi,
  useUserApi,
} from '../api'
import { type UpdateUserFieldValueArgs } from './types'

const useUpdateUserFieldValue = (userId: number, onSuccessCallback?: EmptyCallback) => {
  const { data: user } = useUserApi(userId)

  const { mutate: updateFieldValue, isPending: isUserFieldValueUpdating } =
    useUpdateUserFieldValueApi()

  const { mutate: addFieldValue, isPending: isUserFieldValueAdding } = useAddUserFieldValueApi()

  const { mutate: deleteFieldValue, isPending: isUserFieldValueDeleting } =
    useDeleteUserFieldValueApi()

  const userFieldValues = user?.field_values

  const onSuccess = () => {
    queryClient.refetchQueries({ queryKey: API_QUERY_KEY.USER(userId) })

    if (onSuccessCallback) {
      onSuccessCallback()
    }
  }

  const updateUserFieldValue = async ({ fieldValueId, newValue }: UpdateUserFieldValueArgs) => {
    // Если значение поля пустое, удаляем его
    if (!newValue) {
      return deleteFieldValue(
        {
          params: {
            path: {
              id: fieldValueId,
            },
          },
        },
        { onSuccess },
      )
    }

    // Проверяем наличие значения в user.field_values
    const canAddUserFieldValue = !userFieldValues?.find(({ id }) => {
      return fieldValueId === id
    })

    // Если значениеи не было найдено в user.field_values, создаем новое
    if (canAddUserFieldValue) {
      return addFieldValue(
        {
          body: {
            value: newValue,
            user: userId,
            user_field: fieldValueId,
          } as ApiAddUserFieldValue,
        },
        { onSuccess },
      )
    }

    // newValue не пустое и значение уже существует у пользователя, значит редактируем его
    return updateFieldValue(
      {
        params: {
          path: {
            id: fieldValueId,
          },
        },
        body: { value: newValue },
      },
      { onSuccess },
    )
  }

  return {
    updateUserFieldValue,
    isSubmitting: isUserFieldValueUpdating || isUserFieldValueAdding || isUserFieldValueDeleting,
  }
}

export { useUpdateUserFieldValue }
