import { type PasswordComponentFields } from '@entities/user'
import { useUpdateUserApi } from '@entities/users'
import { useForm } from 'react-hook-form'

import { type UseChangeUserPasswordArgs } from './types'

const useChangeUserPassword = ({ onClose, userId }: UseChangeUserPasswordArgs) => {
  const { mutate: updateUser, isPending: isUserSubmitting } = useUpdateUserApi()

  const methods = useForm<PasswordComponentFields>()

  const handleModalClose = () => {
    methods.reset()
    onClose()
  }

  const onSuccess = () => {
    handleModalClose()
  }

  const handleChangeUserPassword = (data: PasswordComponentFields) => {
    return updateUser(
      {
        params: {
          path: {
            id: userId,
          },
        },
        body: {
          password: data.password,
        },
      },
      { onSuccess },
    )
  }

  return {
    handleChangeUserPassword,
    handleModalClose,
    isUserSubmitting,
    methods,
  }
}

export { useChangeUserPassword }
