import { type ApiUser } from '@shared/api/types'
import { MESSAGE } from '@shared/config'

import { type UsersTableRow } from '../types'

const getUserFieldList = (usersData?: ApiUser[]): UsersTableRow[] | [] => {
  if (!usersData) {
    return []
  }

  // Есть пользователи с пустыми field_values, но они все равно должны отображаться
  const defaultUserField = {
    id: 0,
    username: MESSAGE.EMPTY_VALUE,
    name: MESSAGE.EMPTY_VALUE,
    surname: MESSAGE.EMPTY_VALUE,
    email: MESSAGE.EMPTY_VALUE,
  }

  const userFieldList = usersData.map(({ field_values, id, username }) => {
    const fieldValues = field_values || []

    const userField = fieldValues?.reduce((userField, fieldValue) => {
      return {
        ...userField,
        [fieldValue.user_field]: fieldValue.value || MESSAGE.EMPTY_VALUE,
      }
    }, {})

    return {
      ...defaultUserField,
      ...userField,
      id,
      username,
    }
  }, [])

  return userFieldList || []
}

export { getUserFieldList }
