import { EuiFlexItem, EuiIconTip } from '@elastic/eui'
import { ERROR, LABEL } from '@shared/config'
import { TextInput } from '@shared/ui/form'

import { type LoginInputProps } from './types'

const LoginInput: ReactFc<LoginInputProps> = ({ isSubmitting }) => {
  const usernameValidateOptions = {
    required: ERROR.ENTER_USER_NAME,
    minLength: { value: 3, message: ERROR.USER_NAME_MIN_LENGTH },
    maxLength: { value: 150, message: ERROR.USER_NAME_MAX_LENGTH },
    validate: {
      nameFormat: (value: string) => {
        return /^[a-zA-Z0-9@._+-]+$/.test(value) || ERROR.USER_NAME_FORMAT
      },
    },
  }

  const usernameTooltip = (
    <>
      • Не более 150 символов
      <br />• Только буквы, цифры и символы @/./+/-/
    </>
  )

  return (
    <EuiFlexItem>
      <TextInput
        name="username"
        placeholder={LABEL.USER_NAME}
        registerOptions={usernameValidateOptions}
        hasLabel={false}
        inputProps={{
          disabled: isSubmitting,
          autoComplete: 'username',
          append: [<EuiIconTip key="username" content={usernameTooltip} />],
        }}
        isRequired
      />
    </EuiFlexItem>
  )
}

export { LoginInput }
