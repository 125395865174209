import { EuiHeader, EuiHeaderSection, EuiHeaderSectionItem } from '@elastic/eui'
import { SaveButton } from '@shared/ui/form'
import { noShadow } from '@shared/ui/styles'

import { type UserHeaderProps } from '../types'

const UserHeader: ReactFc<UserHeaderProps> = ({
  onUpdateUserFieldValues,
  isUserFieldValuesSubmitting,
  isSaveButtonActive,
}) => {
  if (!isSaveButtonActive) {
    return null
  }

  return (
    <EuiHeader css={noShadow}>
      <EuiHeaderSection side="right">
        <EuiHeaderSectionItem>
          <SaveButton
            isActive={isSaveButtonActive}
            onClick={onUpdateUserFieldValues}
            isLoading={isUserFieldValuesSubmitting}
          />
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
    </EuiHeader>
  )
}

export { UserHeader }
